// User Pages

.app-logo {
  height: $logo-height;
  width: $logo-width;
  background: url('../../assets/utils/images/logo-inverse.png');
}

.app-logo-inverse {
  height: $logo-height;
  width: $logo-width;
  background: url('../../assets/utils/images/logo.png');
}

.app-login-box {
  .app-logo {
    margin-bottom: $layout-spacer-lg;
  }

  h4 {
    margin-bottom: $layout-spacer-x;
    font-weight: normal;

    div {
      opacity: .6;
    }

    span {
      font-size: $font-size-lg;
    }
  }
}

@keyframes button-animation {
  0% {transform: scale(1);}
  25% {transform: scale(1.25);}
  50% {transform: scale(1.5);}
  75% {transform: scale(1.25);}
  100% {transform: scale(1);}
}

.animated-button {
  animation: button-animation 0.3s forwards;
}